import { all, takeLatest } from 'redux-saga/effects';

import { ChatsTypes } from '../ducks/chat';
import {
  getChats, sendMessage, deleteMessage, noteChat, sendFileMessage, getFilesChat, updateChatStatus, getTag, createTag, addChatTag, removeChatTag,
} from './chat';

import { QuickMessagesType } from '../ducks/quickMessages';
import { createQuickMessages, getQuickMessages, updateQuickMessages } from './quickMessages';

export default function* rootSaga() {
  yield all([
    takeLatest(ChatsTypes.GET_CHATS_REQUEST, getChats),
    takeLatest(ChatsTypes.GET_CHAT_FILES_REQUEST, getFilesChat),
    takeLatest(ChatsTypes.GET_SEND_MESSAGE_REQUEST, sendMessage),
    takeLatest(ChatsTypes.GET_DELETE_MESSAGE_REQUEST, deleteMessage),
    takeLatest(ChatsTypes.SEND_FILE_MESSAGE_REQUEST, sendFileMessage),
    takeLatest(ChatsTypes.GET_NOTE_CHAT_REQUEST, noteChat),
    takeLatest(ChatsTypes.UPDATE_CHAT_STATUS_REQUEST, updateChatStatus),
    takeLatest(ChatsTypes.GET_TAG_REQUEST, getTag),
    takeLatest(ChatsTypes.CREATE_TAG_REQUEST, createTag),
    takeLatest(ChatsTypes.ADD_CHAT_TAG_REQUEST, addChatTag),
    takeLatest(ChatsTypes.REMOVE_CHAT_TAG_REQUEST, removeChatTag),
    takeLatest(QuickMessagesType.GET_QUICK_MESSAGES_REQUEST, getQuickMessages),
    takeLatest(
      QuickMessagesType.CREATE_QUICK_MESSAGES_REQUEST,
      createQuickMessages,
    ),
    takeLatest(
      QuickMessagesType.UPDATE_QUICK_MESSAGES_REQUEST,
      updateQuickMessages,
    ),
  ]);
}
