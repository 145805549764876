import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  // baseURL: 'https://oyf96aw8x8.execute-api.sa-east-1.amazonaws.com/',
  baseURL: 'https://app-api3.guruastral.online',
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  // config.headers = {
  //   ...config.headers,
  //   'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  //   'Access-Control-Allow-Origin': '*',
  // };
  // config.headers['Access-Control-Allow-Origin'] = '*';
  // config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
  // config.headers['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
