import styled from 'styled-components';
import colors from '../../../styles/colors';
import BackgroundButtonImage from './backgroundbutton.png';

/**
 * Componentes Chat
 */

export const MainContainer = styled.div`
  flex: 0.6;
  background: #efefef;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderMainChat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color:  rgb(80, 3, 175);
  background-image: url(${BackgroundButtonImage});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p {
    color: #fff;
    font-weight: bold;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${({ background }) => (background || '#fff')};
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  border-radius: 2.5px;
  padding: 6px;
  margin-left: 5px;

  button {
    background: none;
    border: none;
    color: #fff;
    font-size: 9px;
    font-weight: bold;
  }
`;

export const ClientAvatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
`;

export const MainMessagesContainer = styled.div`
  flex: 0.95;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 15px;
`;

export const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5px;
`;

export const MessageContainer = styled.div`
  background: ${({ fromContainer }) => (fromContainer !== 'true' ? colors.primary : colors.secondary)};
  padding: 25px;
  border-radius: 5px;
  max-width: 50%;
  ${({fromContainer}) => fromContainer === 'true' ? 'cursor: pointer;' :' '}
  a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    border-bottom: 1px solid #fff;
    word-break: break-all;
  }
`;

export const MessageText = styled.p`
  color: #fff;
  word-break: break-all;

  span {
    font-size: 35px;
  }
`;

export const MessageDate = styled.p`
  color: #fff;
  width: 100%;
  margin-top: 5px;
  font-size: 0.6em;
  text-align: right;
`;

export const EmployeeNameText = styled.p`
  color: #fff;
  font-weight: 700;
  width: 100%;
  margin-top: 10px;
  font-size: 0.65em;
  text-align: right;
`;

export const TextInputMainContainer = styled.div`
  flex: 0.05;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0.2px solid #898989;
  background: #fff;
`;

export const TextInputContainer = styled.div`
  display: flex;
  width: 95%;
`;

export const TextInputComponent = styled.textarea`
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  padding: 10px;
  font-family: "Montserrat", sans-serif;
`;

export const SendTextButton = styled.button`
  width: 5%;
  border: none;
  background: #fff;

  svg {
    color: #898989;
    transition: color 400ms;
  }

  svg:hover {
    color: ${colors.primary};
  }
`;

export const UploadButton = styled.span`
  background: none;
  cursor: pointer;

  svg {
    color: #898989;
    transition: color 400ms;
  }

  svg:hover {
    color: ${colors.primary};
  }
`;

export const EmojiPickerContainer = styled.div`
  /* display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end; */
  background: blue;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 7%;
  margin-right: 20%;
  z-index: 99999;
  /* width: 95%; */
`;
