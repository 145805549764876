/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo, useEffect, useState, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import socketio from 'socket.io-client';

import SidebarChat from './Sidebar';
import MainChat from './MainChat';
import RightBarChat from './RightBar';
import ChatsActions from '../../store/ducks/chat';
import QuickMessagesActions from '../../store/ducks/quickMessages';
import { Container } from './styles';

export default function Chat() {
  const chatMessageRef = useRef();
  const chatState = useSelector((state) => state.chat.chats);
  const quickMessageStore = useSelector((store) => store.quickMessages.quickMessages);
  const tagsStore = useSelector((store) => store.chat.tags);
  const [chats, setChats] = useState([]);
  const [chatSelected, setChatSelected] = useState(null);
  const [chatStatusSelected, setChatStatusSelected] = useState(0);
  const [noteChat, setNoteChat] = useState('');

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.userdata);
  const socket = useMemo(
    () => socketio('https://app-api3.guruastral.online', {
      query: {
        user_id: userState.id,
        employee: userState.employee,
        teste: 'teste',
      },
    }),

    [userState],
  );

  useEffect(() => {
    dispatch(ChatsActions.getChatsRequest());
    socket.on('notification', (notification) => {
      dispatch(ChatsActions.getChatsRequest());
    });
  }, [socket]);

  useEffect(() => {
    setChats(chatState);
    if (chatState.length > 0) {
      if (chatSelected) {
        const chatSelec = chatState.filter(
          (chat) => chat.id === chatSelected,
        )[0];

        if (chatSelec.employee_selected_id) {
          setNoteChat(chatSelec.note);
          return setChatStatusSelected(2);
        }
        setNoteChat(chatSelec.note);
        return setChatStatusSelected(chatSelec.chat_status);
      }

      const chatStatus0 = chatState.filter((chat) => (chatStatusSelected === 2
        ? chat.employee_selected_id
        : chat.chat_status === 0 && !chat.employee_selected_id));
      const chatStatus1 = chatState.filter((chat) => (chatStatusSelected === 2
        ? chat.employee_selected_id
        : chat.chat_status === 1 && !chat.employee_selected_id));
      const chatStatus2 = chatState.filter((chat) => chat.employee_selected_id);

      if (chatStatus0.length > 0) {
        setChatStatusSelected(0);
        setNoteChat(chatStatus0[0].note);
        return setChatSelected(chatStatus0[0].id);
      } if (chatStatus1.length > 0) {
        setChatStatusSelected(1);
        setNoteChat(chatStatus1[0].note);
        return setChatSelected(chatStatus1[0].id);
      } if (chatStatus2.length > 0) {
        setChatStatusSelected(2);
        setNoteChat(chatStatus2[0].note);
        return setChatSelected(chatStatus2[0].id);
      }
    } else if (chatState.length === 0 && chatSelected) {
      return setChatSelected(null);
    }
    console.log(chatMessageRef);
  }, [chatState]);

  useEffect(() => {
    dispatch(QuickMessagesActions.getQuickMessagesRequest());
    dispatch(ChatsActions.getTagRequest());
  }, []);

  return (
    <Container>
      <SidebarChat
        chats={chats}
        chatSelected={chatSelected}
        chatStatusSelected={chatStatusSelected}
        setChatSelected={setChatSelected}
        setNoteChat={setNoteChat}
        handleChatStatus={setChatStatusSelected}
      />
      <MainChat
        chatSelected={chatSelected}
        chatStatusSelected={chatStatusSelected}
        chats={chats}
        setChatStatusSelected={setChatStatusSelected}
        userData={userState}
        scrollContainerRef={chatMessageRef}
      />
      {chatSelected && (
        <RightBarChat
          chatSelected={chatSelected}
          chats={chats}
          noteChat={noteChat}
          setNoteChat={setNoteChat}
          userData={userState}
          setChatStatusSelected={setChatStatusSelected}
          quickMessageStore={[...quickMessageStore]}
          tagsStore={[...tagsStore]}
          chatStatusSelected={chatStatusSelected}
        />
      )}
    </Container>
  );
}
